import Head from 'next/head';

import { ScopeLogo } from '@assets';

import classes from '../components/ErrorPage/ErrorPage.module.css';

export const Custom404 = () => {
  return (
    <>
      <Head>
        <title>Scope Tickets</title>
      </Head>

      <div className={classes.errorFallbackWrapper}>
        <div className={classes.errorFallbackMessage}>
          <ScopeLogo
            fill='#0500F2'
            style={{
              height: '60px',
              padding: '20px',
              marginBottom: '20px',
            }}
          />
          {/* TODO: consider different text */}
          <h1>
            Page not found. Please follow the link sent to you to purchase
            tickets.
          </h1>
        </div>
      </div>
    </>
  );
};

export default Custom404;
